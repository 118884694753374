export default function removeMixin (Model) {
  return {
    methods: {
      async remove (data) {
        const { response } = await Model.api().delete(data.id, {
          save: false
        })
        if (response.status === 200) {
          this.$router.go(-1)
          Model.delete(data.$id)
        }
      }
    }
  }
}
