<template>
  <v-dialog
    v-model="dialog"
    max-width="350px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="error"
        class="mx-2"
        rounded
        dark
        v-bind="{...attrs, ...$attrs}"
        v-on="on"
      >
        <slot name="default" />
      </v-btn>
    </template>
    <v-card>
      <v-card-text align="center">
        <div class="mb-5 pt-3">
          <v-icon
            color="red darken-3"
            class="text-h2"
          >
            mdi-alert-circle-outline
          </v-icon>
        </div>
        <div class="mb-5 text-h5">
          {{ message }}
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="blue-grey lighten-2"
          text
          @click="cancel"
        >
          Cancel
        </v-btn>
        <v-btn
          color="red darken-3"
          text
          @click="confirm"
        >
          OK
        </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'VConfirmDialog',
  props: {
    message: {
      type: String,
      default: 'Are you sure ?'
    }
  },
  data () {
    return {
      dialog: undefined
    }
  },
  methods: {
    confirm () {
      this.$emit('confirm')
      this.dialog = false
    },
    cancel () {
      this.$emit('cancel')
      this.dialog = false
    }
  }
}
</script>
