<template>
  <div v-if="instance">
    <EstablishmentForm
      :value="instance"
      :breadcrumbs="breadcrumbs"
    >
      <template #actions="{form, fields, isEqual}">
        <VButtonDialog @confirm="remove(fields)">
          Delete
        </VButtonDialog>
        <v-btn
          :disabled="!form || isEqual"
          color="success"
          class="mx-2"
          rounded
          @click="update(fields)"
        >
          Save
        </v-btn>
      </template>
    </EstablishmentForm>
    <TableList
      :establishment="instance"
    />
  </div>
</template>

<script>
import { Establishment } from '@/store/models'
import VButtonDialog from '@/components/VButtonDialog'
import EstablishmentForm from '@/pages/App/Establishments/__EstablishmentForm__'
import TableList from '@/pages/App/Establishments/__Tables__'
import removeMixin from '@/mixins/apiRemoveMixin'
import updateMixin from '@/mixins/apiUpdateMixin'

export default {
  name: 'EstablishmentId',
  components: { TableList, VButtonDialog, EstablishmentForm },
  mixins: [removeMixin(Establishment), updateMixin(Establishment)],
  computed: {
    breadcrumbs () {
      return [
        {
          text: 'Establishments',
          exact: true,
          to: { name: 'App-Establishments' }
        },
        {
          text: this.instance.name,
          exact: true,
          to: { name: 'App-Establishments-id', params: { id: this.instance.id } }
        }
      ]
    },
    instance () {
      return Establishment.query().with('tables').find(this.$route.params.id)
    }
  }
}
</script>
