export default function updateMixin (Model) {
  return {
    methods: {
      async update (data) {
        const response = await Model.api().patch(null, data, { delete: data.$id })
        response.save()
        this.$router.go(-1)
      }
    }
  }
}
