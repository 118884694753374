<template>
  <v-card flat>
    <v-card-title>
      Tables
    </v-card-title>
    <v-card-actions>
      <v-responsive max-width="260">
        <v-text-field
          v-model="tableCount"
          class="ma-2"
          label="Number of tables"
          outlined
          readonly
          hide-details
          dense
        />
      </v-responsive>
      <v-btn
        color="error"
        class="ma-2"
        outlined
        small
        fab
        :disabled="!tableCount"
      >
        <v-icon
          @click="remove(lastTable)"
        >
          mdi-minus
        </v-icon>
      </v-btn>
      <v-btn
        color="success"
        class="ma-2"
        outlined
        small
        fab
      >
        <v-icon
          color="success"
          @click="create({establishmentId: establishment.id, companyId: establishment.companyId})"
        >
          mdi-plus
        </v-icon>
      </v-btn>
    </v-card-actions>
    <v-card-text>
      <v-row justify="start">
        <v-col
          v-for="table in establishment.tables"
          :key="table.id"
          align="center"
        >
          <TableCard :value="table" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import TableCard from '@/pages/App/Establishments/__TableCard__'
import { Table } from '@/store/models'

export default {
  name: 'TableList',
  components: { TableCard },
  props: {
    establishment: {
      type: Object,
      required: true
    }
  },
  computed: {
    lastTable () {
      const [lastTable] = this.establishment.tables.slice(-1)
      return lastTable
    },
    tableCount () {
      return this.establishment?.tables?.length
    }
  },
  methods: {
    create (data) {
      Table.api().post(null, data)
    },
    remove (data) {
      Table.api().delete(data.id, { delete: data.id })
    }
  }
}
</script>
