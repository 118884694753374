<template>
  <v-card
    :id="value.qr"
    max-width="250"
  >
    <v-card-subtitle>Table {{ value.number }}</v-card-subtitle>

    <QrcodeVue
      class="d-inline-block"
      :value="'https://qr.lanote.co/' + value.qr"
      size="175"
    />
    <v-card-subtitle>https://qr.lanote.co/{{ value.qr }}</v-card-subtitle>

    <v-divider class="mx-4" />

    <!--    <v-card-actions>-->
    <!--      <v-spacer />-->
    <!--      <v-btn-->
    <!--        color="deep-purple lighten-2"-->
    <!--        text-->
    <!--        @click="$htmlToPaper(value.qr)"-->
    <!--      >-->
    <!--        Print-->
    <!--      </v-btn>-->
    <!--    </v-card-actions>-->
  </v-card>
</template>

<script>
import QrcodeVue from 'qrcode.vue'

export default {
  name: 'TableCard',
  components: { QrcodeVue },
  props: {
    value: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
